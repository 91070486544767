<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0896 5.58934C13.415 5.2639 13.415 4.73626 13.0896 4.41083C12.7641 4.08539 12.2365 4.08539 11.9111 4.41083L6.91107 9.41083C6.58563 9.73626 6.58563 10.2639 6.91107 10.5893L11.9111 15.5893C12.2365 15.9148 12.7641 15.9148 13.0896 15.5893C13.415 15.2639 13.415 14.7363 13.0896 14.4108L8.67884 10.0001L13.0896 5.58934Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
import { Vue } from "vue-class-component";

export default class ChevronLeft extends Vue {}
</script>
